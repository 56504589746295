<template>
  <el-tooltip effect="dark" :content="tableHeaderTooltipText[toolKey]" placement="top-start">
    <i class="el-icon-warning-outline icon"></i>
  </el-tooltip>
</template>

<script>
const tableHeaderTooltipText = {
  serviceNum: '按照服务单开始服务的时间统计',
  serviceRatio: '同岗位下服务单量占比',
  serviceTimeAvg: '按照服务单开始和结束时间计算',
  serviceConversionNum: '基于本期服务单，产生过后期单的服务单数量',
  servicePayNum: '在所选时间内，产生过后期单的服务单数量',
  salesServiceNum: '只统计产生业绩的服务单',
}
export default {
  name: '',
  props: {
    toolKey: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tableHeaderTooltipText,
    }
  },

  mounted() {},

  methods: {},
}
</script>

<style lang="less" scoped>
.icon {
  transform: rotate(180deg);
  font-size: 14px;
}
</style>
