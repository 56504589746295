<template>
  <div class="personnelManagement">
    <template v-if="hasPermission('SUPPLY_BASE_INFO') || hasPermission('SUPPLY_PERFORMANCE_INFO')">
      <el-tabs v-model="currentTab" class="tabs" @tab-click="tabClick">
        <el-tab-pane
          v-if="hasPermission('SUPPLY_BASE_INFO')"
          label="基本信息"
          name="base"
        ></el-tab-pane>
        <el-tab-pane
          v-if="hasPermission('SUPPLY_PERFORMANCE_INFO')"
          label="业绩信息"
          name="score"
        ></el-tab-pane>
      </el-tabs>
      <div v-if="currentTab == 'base' && hasPermission('SUPPLY_BASE_INFO')" class="content-wrap">
        <page-header :post-list="postList" @search="search" />
        <div class="table">
          <div class="table-header">
            <div>
              <!-- <el-button icon="el-icon-plus" type="primary" size="small" @click="add"
                >添加服务人员</el-button
              > -->
              <el-button size="small" @click="isEnable('批量', 1)">停用</el-button>
              <el-button size="small" @click="isEnable('批量', 0)">启用</el-button>
              <!-- <el-button size="small" @click="isResign('批量', 1)">离职</el-button>
              <el-button size="small" @click="isResign('批量', 0)">在职</el-button> -->
            </div>

            <span style="color: #999">账号初始密码为：dp123456</span>
          </div>
          <Table
            ref="table"
            :table-data="tableData"
            :total="total"
            :loading="loading"
            @edit="edit"
            @isEnable="isEnable"
            @handleSelectionChange="handleSelectionChange"
          />
          <Pages
            :current-page="form.pageNumber"
            :total="total"
            @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"
          />
        </div>
      </div>

      <div v-else-if="hasPermission('SUPPLY_PERFORMANCE_INFO')" class="content-wrap">
        <div class="table">
          <ScoreTable
            ref="scoreTable"
            :filter-post-list="filterPostList"
            :init-post="initPost"
          ></ScoreTable>
        </div>
      </div>
    </template>
    <el-empty v-else description="暂不权限访问，请联系门店管理员"></el-empty>
    <Add
      :work-days="workDays"
      :type="type"
      :row="row"
      :post-list="postList"
      :dialog-visible.sync="dialogVisible"
      @refresh="getTableData"
    />
  </div>
</template>

<script>
import pageHeader from './header'
import Table from './table'
import Add from './components/add'
import { pageList, updateDisableIds, updateResignByIds, getPostList } from '@/api/user'
import { getDictionaryList } from '@/api/common'
import ScoreTable from './scoreTable.vue'
import { hasPermission } from '@/utils/buttonAuthority.js'

export default {
  components: {
    pageHeader,
    Table,
    Add,
    ScoreTable,
  },
  data() {
    return {
      dialogVisible: false,
      tableData: [{}],
      scoreTableData: [],
      total: 0,
      multipleSelection: [],
      form: {
        pageSize: 10,
        pageNumber: 1,
        data: {
          sourceFrom: 4,
        },
      },
      loading: false,
      row: {},
      radio: '',
      postList: [],
      filterPostList: [],
      type: '',
      workDays: [],
      currentTab: 'base',
      scoreTableTime: {
        start: '',
        end: '',
      },
      selectPostIdList: [],
      initPost: {},
    }
  },
  computed: {
    powerList: function () {
      return this.$store.getters.getPowerList
    },
  },
  watch: {},
  async mounted() {
    if (this.$route.query.currentTab) {
      this.currentTab = this.$route.query.currentTab
    }
    await getPostList().then((res) => {
      this.postList = res.data.map((v) => ({ ...v, isProminent: false }))
      this.filterPostList = this.postList.map((el) => ({ text: el.postName, value: el.postId }))
      console.log(this.filterPostList)
      if (this.$route.query.postId) {
        this.initPost = this.filterPostList.find((el) => el.value === this.$route.query.postId)
      }
    })
    await getDictionaryList({ data: { key: 'store_workday' } }).then((res) => {
      this.workDays = res.data.childs
    })
    if (this.currentTab === 'base' && hasPermission('SUPPLY_BASE_INFO')) {
      await this.getTableData()
    }
  },
  methods: {
    hasPermission,
    getTableData() {
      this.loading = true
      this.form.data.sourceFrom = 4
      pageList(this.form).then((res) => {
        this.loading = false
        this.tableData = res.data.content
        this.total = Number(res.data.total)
      })
    },
    search(data) {
      this.form.pageNumber = 1
      this.form.data = data
      this.getTableData()
    },

    isResign(row, e) {
      const data = {}
      data.isResign = e
      if (row === '批量') {
        if (this.multipleSelection.length === 0) {
          this.$message('请至少选择一条数据')
          return
        }
        data.storeUserIds = this.multipleSelection.map((e) => e.storeUserId)
      } else {
        data.storeUserIds = [row.storeUserIds]
      }
      const tip = e === 0 ? '在职' : '离职'
      this.$confirm('是否确认' + tip + '?', tip + '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          updateResignByIds({ data }).then(() => {
            this.getTableData()
            this.$message({
              type: 'success',
              message: '操作成功!',
            })
          })
        })
        .catch(() => {})
    },
    isEnable(row, e) {
      const data = {}
      data.isDisable = e
      if (row === '批量') {
        if (this.multipleSelection.length === 0) {
          this.$message('请至少选择一条数据')
          return
        }
        data.storeUserIds = this.multipleSelection.map((e) => e.storeUserId)
      } else {
        data.storeUserIds = [row.storeUserId]
      }
      const tip = e === 0 ? '启用' : '停用'
      this.$confirm('是否确认' + tip + '?', tip + '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          updateDisableIds({ data }).then(() => {
            this.getTableData()
            this.$message({
              type: 'success',
              message: tip + '成功!',
            })
          })
        })
        .catch(() => {})
    },
    add() {
      this.type = 'add'
      this.row = {}
      this.dialogVisible = true
    },
    edit(row) {
      this.row = { ...row }
      this.type = 'edit'
      this.dialogVisible = true
    },
    toggleSelection(rows) {
      this.$refs.table.toggleSelection(rows)
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.form.pageNumber = 1
      this.getTableData()
    },
    handleCurrentChange(val) {
      this.form.pageNumber = val
      this.getTableData()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    tabClick({ name }) {
      if (name === 'base') this.getTableData()
    },
    timeSelect(e) {
      this.scoreTableTime.start = e[0]
      this.scoreTableTime.end = e[1]
    },
    submitFilter(e) {
      e.forEach((text) => {})
    },
  },
}
</script>
<style lang="less" scoped>
.personnelManagement {
  width: 100%;
  height: 100%;
  .tabs {
    /deep/.el-tabs {
      background: #fff;
      padding-left: 30px;

      .el-tabs__item.is-active {
        color: #2861e2;
      }
      .el-tabs__item:hover {
        color: #2861e2;
      }

      .el-tabs__active-bar {
        background: #2861e2;
      }

      .el-tabs__header {
        margin: 0;
      }

      .el-tabs__nav-wrap:after {
        display: none;
      }
    }
  }

  .content-wrap {
    margin-top: 20px;
    padding: 20px;
    background: #fff;
    border-radius: 6px;

    .table-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }
  }

  .moreAction {
    font-size: 14px;
    color: #666;
  }
}

::v-deep {
  .el-button--primary {
    background: #2861e2;
    border-color: #2861e2;
  }
  .el-button--primary:focus,
  .el-button--primary:hover {
    background: #5381e8;
    border-color: #5381e8;
    color: #fff;
  }
  .el-button--primary.is-plain {
    color: #2861e2;
    background: #fff;
    border-color: #a9c0f3;
  }
  .el-button:hover,
  .el-button--primary.is-plain:hover,
  .el-button--primary.is-plain:focus {
    background: #2861e2;
    border-color: #2861e2;
    color: #fff;
  }

  .el-button--text {
    color: #2861e2;
  }
}
</style>
