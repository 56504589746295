<template>
  <div class="timePicker">
    <div
      class="time-item"
      :class="{ 'time-item-checked': clickState == 'lastWeekDay' }"
      @click="itemClick('lastWeekDay')"
    >
      近7天
    </div>
    <div
      class="time-item"
      :class="{ 'time-item-checked': clickState == 'lastMonthDay' }"
      @click="itemClick('lastMonthDay')"
    >
      近30天
    </div>
    <div
      class="time-item"
      :class="{ 'time-item-checked': clickState == 'lastYearDay' }"
      @click="itemClick('lastYearDay')"
    >
      近1年
    </div>

    <el-date-picker
      v-model="timeList"
      :picker-options="pickerOptions"
      class="date-picker"
      :clearable="false"
      prefix-icon=""
      type="daterange"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      value-format="yyyy-MM-dd"
      @change="datePickChange"
    ></el-date-picker>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    const vm = this

    return {
      clickState: 'lastWeekDay',
      timeList: [],
      pickerOptions: {
        onPick({ maxDate, minDate }) {
          if (!maxDate) {
            vm.firstClickTime = minDate
          } else {
            vm.firstClickTime = ''
          }
        },
        disabledDate(time) {
          if (vm.firstClickTime) {
            return (
              time.getTime() - 60 * 60 * 24 * 1000 * 364 > vm.firstClickTime.getTime() ||
              time.getTime() + 60 * 60 * 24 * 1000 * 364 < vm.firstClickTime.getTime()
            )
          }
        },
      },
    }
  },
  mounted() {
    this.timeList = this.$commonFn.getDateInterval('lastWeekDay')
    this.$emit('timeSelect', this.timeList)
  },
  methods: {
    itemClick(type) {
      // if (this.clickState === type) return

      this.clickState = type

      const timeList = this.$commonFn.getDateInterval(type)
      this.timeList = timeList
      this.$emit('timeSelect', timeList)

      // if (type === 'lastWeekDay') {
      // }
      // if (type === 'lastMonthDay') {
      // }
      // if (type === 'lastYearDay') {
      // }
    },
    datePickChange(e) {
      this.clickState = 'custom'

      this.timeList[0] = this.dateFormat(new Date(e[0]), 'start')
      this.timeList[1] = this.dateFormat(new Date(e[1]), 'end')

      this.$emit('timeSelect', this.timeList)
    },
    dateFormat(date, type) {
      const year = date.getFullYear()
      const month = date.getMonth() > 8 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`
      const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`
      return year + '-' + month + '-' + day + (type === 'start' ? ' 00:00:00' : ' 23:59:59')
    },
  },
}
</script>

<style lang="scss" scoped>
.timePicker {
  display: flex;

  .time-item {
    padding: 7px 10px;
    color: #000000;
    font-size: 14px;
    cursor: pointer;
    transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
  }

  .time-item-checked {
    background: rgba(40, 97, 226, 0.12);
    color: #2861e2;
  }

  .time-item + .time-item {
    margin-left: 16px;
  }

  .date-picker {
    width: 200px;
    border: none;
    background: #f5f6f9;
    margin-left: 20px;
  }

  ::v-deep .el-date-editor {
    padding: 4px 16px;

    .el-range__icon {
      display: none;
    }

    .el-range__close-icon {
      display: none;
    }

    .el-range-input {
      width: 50%;
      background: #f5f6f9;
    }

    .el-range-separator {
      padding: 0;
      width: auto;
    }
  }
}
</style>
