<template>
  <div class="search">
    <div class="search-item">
      <span class="search-item-label">岗位</span>
      <el-select v-model="form.postId" filterable clearable placeholder="请选择">
        <el-option
          v-for="(n, i) in postList"
          :key="i"
          :label="n.postName"
          :value="n.postId"
        ></el-option>
      </el-select>
    </div>

    <div class="search-item">
      <span class="search-item-label">服务人员</span>
      <el-input
        v-model="form.userNameOrId"
        size="mini"
        placeholder="请输入服务人员名称/ID"
      ></el-input>
    </div>
    <div class="search-item">
      <span class="search-item-label">手机号</span>
      <el-input v-model="form.phoneNumber" size="mini" placeholder="请输入"></el-input>
    </div>
    <div class="search-item">
      <span class="search-item-label">与门店关系</span>
      <el-select v-model="form.isResident" placeholder="请选择">
        <el-option label="全部" value=""></el-option>
        <el-option label="常驻" value="1"></el-option>
        <el-option label="关联" value="2"></el-option>
      </el-select>
    </div>
    <div class="search-item">
      <span class="search-item-label">ERP员工ID</span>
      <el-input v-model="form.erpUserId" size="mini" placeholder="请输入ERP员工ID"></el-input>
    </div>
    <div class="search-item">
      <span class="search-item-label">工作性质</span>
      <el-select v-model="form.isFullTime" placeholder="请选择">
        <el-option label="全部" value=""></el-option>
        <el-option label="全职" value="1"> </el-option>
        <el-option label="兼职" value="0"> </el-option>
      </el-select>
    </div>
    <div class="search-item">
      <span class="search-item-label">在职状态</span>
      <el-select v-model="form.isResign" placeholder="请选择">
        <el-option label="全部" value=""></el-option>
        <el-option label="在职" value="0"></el-option>
        <el-option label="离职" value="1"></el-option>
      </el-select>
    </div>
    <div v-if="unfold" class="search-item">
      <span class="search-item-label">启用状态</span>
      <el-select v-model="form.isDisable" placeholder="请选择">
        <el-option label="全部" value=""></el-option>
        <el-option label="启用" value="0"></el-option>
        <el-option label="停用" value="1"></el-option>
      </el-select>
    </div>
    <div class="search-item-handle search-item-b">
      <span class="text-button" @click="checkFold">
        <span style="margin-right: 4px">{{ unfold ? '收起' : '展开' }}</span>
        <i
          class="el-icon-arrow-down"
          style="transition: transform 0.2s ease-in-out"
          :class="{ 'icon-up': unfold }"
        ></i>
      </span>
      <el-button type="primary" icon="el-icon-search" class="btn" @click="onSubmit">查询</el-button>
      <el-button icon="el-icon-refresh-left" class="btn" @click="reset">重置</el-button>
    </div>
  </div>
</template>

<script>
// import moment from "moment";
export default {
  name: 'PageHeader',
  components: {},
  props: {
    postList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {},
      fold: false,
      customerCate: [],
      unfold: false,
    }
  },
  mounted() {},
  methods: {
    onSubmit() {
      this.$emit('search', this.form)
    },
    reset() {
      this.form = {}
      this.$emit('search', {})
    },

    checkFold() {
      this.unfold = !this.unfold
    },
  },
}
</script>
<style lang="less" scoped>
.search-item-handle {
  margin-bottom: 20px;
  width: calc(20% - 20px);
  display: flex;
  align-items: center;

  .text-button {
    color: #2861e2;
    font-size: 14px;
    cursor: pointer;
    margin-right: 20px;
    white-space: nowrap;
  }

  .icon-up {
    transform: rotate(180deg);
  }
}
.search {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
  gap: 20px;
}
.search-item {
  display: flex;
  align-items: center;
  width: 22%;
  height: 38px;
  background: #f5f6f9;
  border-radius: 4px;
  padding-left: 12px;

  .search-item-label {
    white-space: nowrap;
    font-size: 14px;
    color: #999999;
  }

  /deep/ .el-input__inner {
    border: none;
    background: #f5f6f9;
    font-size: 14px;
  }

  /deep/ .el-range-input {
    background: #f5f6f9;
    font-size: 14px;
  }

  /deep/ .el-date-editor .el-range-separator {
    width: unset;
  }

  /deep/ .el-input {
    input {
      padding-left: 24px;
    }

    input::placeholder {
      font-size: 14px;
      color: #999999;
      line-height: 20px;
    }
  }

  /deep/ .el-date-editor {
    width: 185px;
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 12px;

    input::placeholder {
      font-size: 14px;
      color: #999999;
    }
  }
}

.search-item:has(+ .search-item) {
  // margin-right: 20px;
}

.search-item:has(+ .btn) {
  // margin-right: 20px;
}

//  .btn {

//   /deep/ el-button--primary {
//       background: #2861e2;
//       border-color: #2861e2;
//     }
//     .el-button--primary:focus,
//     .el-button--primary:hover {
//       background: #5381e8;
//       border-color: #5381e8;
//       color: #fff;
//     }
//     .el-button--primary.is-plain {
//       color: #2861e2;
//       background: #fff;
//       border-color: #a9c0f3;
//     }
//     .el-button:hover,
//     .el-button--primary.is-plain:hover,
//     .el-button--primary.is-plain:focus {
//       background: #2861e2;
//       border-color: #2861e2;
//       color: #fff;
//     }

//     .el-button--text {
//       color: #2861e2;
//     }

// }
/deep/ * {
  .el-select {
    width: 100%;
  }
}
</style>
