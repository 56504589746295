<template>
  <el-table v-loading="loading" border :data="tableData" height="500px" @sort-change="sortChange">
    <el-table-column type="index" label="序号" fixed="left"> </el-table-column>
    <el-table-column prop="saleUserId" label="员工ID" min-width="93"></el-table-column>
    <el-table-column prop="saleUserName" label="姓名" min-width="130" fixed="left">
      <template slot-scope="{ row }">
        <div class="service-user-name">
          <span>{{ row.saleUserName }}</span>
          <span v-if="row.isResident === 2" class="text-icon resident-icon">关联</span>
          <span v-if="row.isFullTime === 0" class="text-icon twice-icon">兼</span>
        </div>
      </template>
    </el-table-column>

    <el-table-column prop="orderNum" label="订单量" min-width="120" sortable="custom" />
    <el-table-column prop="orderRatio" label="订单量占比(%)" min-width="120" />
    <el-table-column prop="serviceNum" label="服务单量" min-width="120">
      <template #header>
        服务单量
        <tooltip-table-header tool-key="salesServiceNum" />
      </template>
    </el-table-column>
    <el-table-column prop="serviceRatio" label="服务单量占比(%)" min-width="120" />
    <el-table-column prop="earlierAmount" label="前期金额" min-width="120" sortable="custom" />
    <el-table-column prop="earlierSumAmount" label="前期业绩" min-width="120" />
    <el-table-column prop="laterSumAmount" label="后期业绩" min-width="120" />
    <el-table-column prop="secondSumAmount" label="复购业绩" min-width="120" />
    <el-table-column prop="naturalFlowSumAmount" label="自然流量业绩" min-width="120" />
    <el-table-column prop="sumAmount" label="整体业绩" min-width="120" sortable="custom" />
    <el-table-column prop="earlierAmountAvg" label="前期服务均销" min-width="120" />
    <el-table-column prop="laterAmountAvg" label="后期服务均销" min-width="120" />
    <el-table-column prop="amountAvg" label="整体服务均销" min-width="120" sortable="custom" />
  </el-table>
</template>

<script>
import tooltipTableHeader from './components/tooltip-table-header.vue'
export default {
  components: { tooltipTableHeader },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {}
  },

  mounted() {},

  methods: {
    // 排序
    sortChange({ prop, order }) {
      this.$emit('sortChange', { prop, order })
    },
  },
}
</script>

<style lang="scss" scoped>
.service-user-name {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.text-icon {
  text-align: center;
  height: 15px;
  line-height: 15px;
  margin-left: 4px;
  font-size: 12px;
  border-radius: 1px 1px 1px 1px;
}

.twice-icon {
  width: 14px;
  background: rgba(248, 223, 161, 0.2);
  border: 1px solid #b1a996;
  color: #726a56;
}

.main-icon {
  width: 14px;
  background: #fee8cf;
  border: 1px solid #fee8cf;
  color: #f6884e;
}

.resident-icon {
  width: 26px;
  background: rgba(0, 161, 153, 0.05);
  border: 1px solid #add6d5;
  color: #00a199;
}
</style>
