<template>
  <div v-if="filterPostList.length">
    <div class="service-tabs">
      <span class="tab" :class="{ 'tab-active': tabValue === 'user' }" @click="tabChange('user')">
        服务人员
      </span>
      <span
        class="tab"
        :class="{ 'tab-active': tabValue === 'consultant' }"
        @click="tabChange('consultant')"
      >
        销售顾问
      </span>
    </div>
    <div class="table-header">
      <div>
        <el-button
          v-hasBtn="'SUPPLY_PERFORMANCE_INFO_EXPORT'"
          type="primary"
          size="small"
          icon="el-icon-download"
          :loading="loadingExport"
          @click="Export"
          >导出</el-button
        >
      </div>

      <timePicker ref="timePicker" @timeSelect="timeSelect"></timePicker>
    </div>

    <el-table
      v-if="tabValue === 'user'"
      key="1"
      v-loading="loading"
      border
      :data="tableData"
      height="500px"
      @sort-change="sortChange"
    >
      <el-table-column type="index" label="序号" fixed="left"> </el-table-column>
      <el-table-column prop="serviceUserId" label="员工ID" min-width="93"></el-table-column>
      <el-table-column prop="serviceUserName" label="姓名" min-width="130" fixed="left">
        <template slot-scope="{ row }">
          <div class="service-user-name">
            <span>{{ row.serviceUserName }}</span>
            <span v-if="row.isResident === 2" class="text-icon resident-icon">关联</span>
            <span v-if="row.isFullTime === 0" class="text-icon twice-icon">兼</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="postName" label="岗位" min-width="100">
        <template slot="header">
          <dropdown
            :check-post="checkPost"
            :filter-post-list="filterPostList"
            @change="dropdownChange"
          />
        </template>
        <template slot-scope="{ row }">
          <div class="service-user-name">
            <span>{{ row.postName }}</span>
            <span v-if="row.isMainPost" class="text-icon main-icon">主</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="serviceNum" label="服务单量" min-width="120" sortable="custom">
        <template #header>
          服务单量
          <tooltip-table-header tool-key="serviceNum" />
        </template>
      </el-table-column>
      <el-table-column prop="serviceRatio" label="服务单量占比(%)" min-width="135">
        <template #header>
          服务单量占比(%)
          <tooltip-table-header tool-key="serviceRatio" />
        </template>
      </el-table-column>
      <el-table-column prop="serviceTimeAvg" label="平均服务时长" min-width="115">
        <template #header>
          平均服务时长
          <tooltip-table-header tool-key="serviceTimeAvg" />
        </template>
      </el-table-column>
      <el-table-column prop="serviceConversionNum" label="本期服务转化单量" min-width="145">
        <template #header>
          本期服务转化单量
          <tooltip-table-header tool-key="serviceConversionNum" />
        </template>
      </el-table-column>
      <el-table-column
        prop="conversionRatio"
        label="转化率(%)"
        min-width="136"
        sortable="custom"
      ></el-table-column>
      <el-table-column prop="servicePayNum" label="本期累计转化单量" min-width="145">
        <template #header>
          本期累计转化单量
          <tooltip-table-header tool-key="servicePayNum" />
        </template>
      </el-table-column>
      <el-table-column
        prop="earlierAmount"
        label="前期金额"
        min-width="136"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="earlierAdditionAmount"
        label="前期散件加购业绩"
        min-width="128"
      ></el-table-column>
      <el-table-column
        prop="earlierSecondarySalesAmount"
        label="前期套系加购业绩"
        min-width="128"
      ></el-table-column>
      <el-table-column
        prop="laterAdditionAmount"
        label="后期散件加购业绩"
        min-width="128"
      ></el-table-column>
      <el-table-column
        prop="laterSecondarySalesAmount"
        label="后期套系加购业绩"
        min-width="128"
      ></el-table-column>
      <el-table-column
        prop="earlierSumAmount"
        label="前期业绩"
        min-width="128"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="laterSumAmount"
        label="后期业绩"
        min-width="128"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="sumAmount"
        label="整体业绩"
        min-width="128"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="earlierAmountAvg"
        label="前期服务均销"
        min-width="128"
      ></el-table-column>
      <el-table-column prop="laterAmountAvg" label="后期服务均销" min-width="128"></el-table-column>
      <el-table-column prop="amountAvg" label="整体服务均销" min-width="128"></el-table-column>
    </el-table>

    <salesTable
      v-else
      key="2"
      :table-data="tableData"
      :loading="loading"
      @sortChange="sortChange"
    />
    <Pages
      :current-page="page.pageNumber"
      :total="total"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    />
  </div>
</template>

<script>
import timePicker from './components/timePicker.vue'
import dropdown from './components/dropdown.vue'
import tooltipTableHeader from './components/tooltip-table-header.vue'
import salesTable from './salesTable'
import {
  supplyIndexStoreUserPerformance,
  performanceExport,
  getStoreUserSalePerformancePage,
  storeUserSalePerformanceExport,
} from '@/api/order.js'
export default {
  components: { timePicker, dropdown, tooltipTableHeader, salesTable },
  props: {
    filterPostList: {
      type: Array,
      default: () => [],
    },
    initPost: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      checkPost: {
        text: '',
        value: '',
      },
      page: {
        pageSize: 10,
        pageNumber: 1,
      },
      sort: {
        sortField: '',
        sort: '',
      },
      total: 0,
      scoreTableTime: {
        beginDate: '',
        endDate: '',
      },
      loading: false,
      tableData: [],
      loadingExport: false,
      tabValue: 'user',
    }
  },
  watch: {
    initPost: {
      handler(val) {
        this.checkPost.text = val.text
        this.checkPost.value = val.value
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    if (this.$route.query.childTab) {
      this.tabValue = this.$route.query.childTab
    }
    // this.getTableData()
  },
  methods: {
    reset() {
      this.page = this.$options.data(this).page
      this.sort = this.$options.data(this).sort
      this.scoreTableTime = this.$options.data(this).scoreTableTime
    },
    tabChange(value) {
      this.tabValue = value
      this.reset()
      this.$refs.timePicker.itemClick('lastWeekDay')
      // this.getTableData()
    },
    // 排序
    sortChange({ prop, order }) {
      const props = {
        serviceNum: 'serviceNum',
        conversionRatio: 'conversionRatioSort',
        earlierAmount: 'earlierAmountSort',
        earlierSumAmount: 'earlierSumAmountSort',
        laterSumAmount: 'laterSumAmountSort',
        sumAmount: 'sumAmountSort',
        amountAvg: 'amountAvgSort',
        orderNum: 'orderNum',
      }
      if (order) {
        this.sort.sortField = props[prop]
        this.sort.sort = order === 'descending' ? '2' : '1'
      } else {
        this.sort.sortField = ''
        this.sort.sort = ''
      }
      this.getTableData()
    },
    handleSizeChange(val) {
      this.page.pageSize = val
      this.page.pageNumber = 1
      this.getTableData()
    },
    handleCurrentChange(val) {
      this.page.pageNumber = val
      this.getTableData()
    },
    timeSelect(e) {
      console.log(e)
      this.page.pageNumber = 1
      this.scoreTableTime.beginDate = e[0]
      this.scoreTableTime.endDate = e[1]
      this.getTableData()
    },
    getTableData() {
      this.tableData = []
      if (this.tabValue === 'user') {
        this.supplyIndexStoreUserPerformance()
      } else {
        this.getStoreUserSalePerformancePage()
      }
    },
    supplyIndexStoreUserPerformance() {
      this.loading = true
      supplyIndexStoreUserPerformance({
        data: {
          ...this.scoreTableTime,
          postId: this.checkPost.value,
          ...this.sort,
        },
        ...this.page,
      }).then((res) => {
        this.tableData = res.data.content
        this.total = parseInt(res.data.total)
        this.loading = false
      })
    },
    getStoreUserSalePerformancePage() {
      this.loading = true
      getStoreUserSalePerformancePage({
        data: {
          ...this.scoreTableTime,
          ...this.sort,
        },
        ...this.page,
      }).then((res) => {
        this.tableData = res.data.content
        this.total = parseInt(res.data.total)
        this.loading = false
      })
    },
    dropdownChange(e) {
      // 单独赋值，利用引用类型的特性去更新dropdown
      this.checkPost.text = e.text
      this.checkPost.value = e.value
      this.getTableData()
    },
    Export() {
      this.loadingExport = true
      if (this.tabValue === 'user') {
        this.performanceExport()
      } else {
        this.storeUserSalePerformanceExport()
      }
    },

    storeUserSalePerformanceExport() {
      storeUserSalePerformanceExport({
        data: {
          ...this.scoreTableTime,
          ...this.sort,
        },
      })
        .then((res) => {
          const url = window.URL.createObjectURL(
            new Blob([res], { type: 'application/octet-stream' }),
          )
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', '销售顾问表.xlsx')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          window.URL.revokeObjectURL(url)
          this.loadingExport = false
          this.$message.success('导出成功！')
        })
        .catch((e) => {
          console.log(e)
          this.loadingExport = false
        })
    },
    performanceExport() {
      performanceExport({
        data: {
          ...this.scoreTableTime,
          postId: this.checkPost.value,
          ...this.sort,
        },
      })
        .then((res) => {
          const url = window.URL.createObjectURL(
            new Blob([res], { type: 'application/octet-stream' }),
          )
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', '服务人员表.xlsx')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          window.URL.revokeObjectURL(url)
          this.loadingExport = false
          this.$message.success('导出成功！')
        })
        .catch((e) => {
          console.log(e)
          this.loadingExport = false
        })
    },
  },
}
</script>

<style lang="less" scoped>
.filter-icon {
  cursor: pointer;
}

.filter-list {
  overflow: hidden;
  background: #fff;
  height: 200px;

  .filter-wrap {
    display: flex;
    flex-direction: column;
    padding: 12px 0 12px 12px;
    overflow-x: hidden;
    overflow-y: auto;
    height: 142px;
    border-bottom: 1px solid #e4e7ed;

    &::-webkit-scrollbar {
      width: 5px;
      // height: ;
    }

    &::-webkit-scrollbar-thumb {
      background: #bfbfbf;
      border-radius: 5px;
    }
  }

  .bottom-button {
    display: flex;

    .button-item {
      flex: 1;
      height: 34px;
      line-height: 34px;
      text-align: center;
      cursor: pointer;

      &:hover {
        color: #2861e2;
      }
    }
  }
}

.service-user-name {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.text-icon {
  text-align: center;
  height: 15px;
  line-height: 15px;
  margin-left: 4px;
  font-size: 12px;
  border-radius: 1px 1px 1px 1px;
}

.twice-icon {
  width: 14px;
  background: rgba(248, 223, 161, 0.2);
  border: 1px solid #b1a996;
  color: #726a56;
}

.main-icon {
  width: 14px;
  background: #fee8cf;
  border: 1px solid #fee8cf;
  color: #f6884e;
}

.resident-icon {
  width: 26px;
  background: rgba(0, 161, 153, 0.05);
  border: 1px solid #add6d5;
  color: #00a199;
}
</style>

<style lang="less">
.score-table-pop {
  background: red;
  min-width: unset !important;
  padding: 0 !important;
}

.table-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.el-dropdown-link {
  font-size: 12px;
  color: #909399;
  white-space: nowrap;
}

.service-tabs {
  background: #f4f4f4;
  border-radius: 4px 4px 4px 4px;
  padding: 2px;
  display: inline-block;
  margin-bottom: 20px;

  .tab {
    display: inline-block;
    padding: 0px 16px;
    height: 32px;
    line-height: 32px;
    font-size: 16px;
    color: #303133;
    cursor: pointer;
    transition: all 0.2s;
  }

  .tab-active {
    font-weight: 500;
    color: #2861e2;
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
  }
}
</style>
