<template>
  <el-table
    ref="multipleTable"
    v-loading="loading"
    :data="tableData"
    border
    style="width: 100%"
    max-height="500px"
    @selection-change="handleSelectionChange"
  >
    <el-table-column
      type="selection"
      min-width="55"
      fixed="left"
      :selectable="selectable"
    ></el-table-column>
    <el-table-column prop="userName" label="服务人员" min-width="160" fixed="left">
      <template slot-scope="{ row }">
        <div class="userInfo">
          <img class="img" :src="row.avatarUrl || defaultImage" alt="" />
          <div>
            <el-tooltip
              class="item"
              effect="dark"
              :disabled="row.userName.length < 7"
              :content="row.userName"
              placement="top"
            >
              <div :class="{ overflow: row.userName.length > 6 }">
                {{ row.userName }}
              </div>
            </el-tooltip>
            <div class="userId">ID：{{ row.userId }}</div>
          </div>
        </div>
      </template>
    </el-table-column>
    <el-table-column prop="phoneNumber" label="登录手机号" min-width="100"></el-table-column>
    <el-table-column prop="mainPostName" label="主岗位" min-width="180">
      <template slot-scope="{ row }">
        <span v-if="row.mainPostName">{{ row.mainPostName }}</span>
        <span v-else>--</span>
      </template>
    </el-table-column>
    <el-table-column prop="partTimePostNames" label="兼职岗位" min-width="180">
      <template slot-scope="{ row }">
        <span v-if="row.partTimePostNames">{{ row.partTimePostNames }}</span>
        <span v-else>--</span>
      </template>
    </el-table-column>
    <el-table-column prop="serviceDayNames" label="可服务日" min-width="180"></el-table-column>
    <el-table-column prop="serviceDayNames" label="与门店关系" min-width="100">
      <template slot-scope="scope">
        {{ scope.row.isResident == 2 ? '关联' : '常驻' }}
      </template>
    </el-table-column>
    <el-table-column prop="roleNames" label="角色权限" min-width="100">
      <template slot-scope="{ row }">
        <span v-if="row.roleNames">{{ row.roleNames }}</span>
        <span v-else>--</span>
      </template>
    </el-table-column>
    <el-table-column prop="roleNames" label="工作性质" min-width="100">
      <template slot-scope="scope">
        {{ scope.row.isFullTime == 0 ? '兼职' : '全职' }}
      </template>
    </el-table-column>
    <el-table-column prop="isResign" label="在职状态" min-width="80">
      <template slot-scope="scope">
        {{ scope.row.isResign == 0 ? '在职' : '离职' }}
      </template>
    </el-table-column>
    <el-table-column prop="erpUserId" label="ERP员工ID" min-width="180">
      <template slot-scope="{ row }">
        <span v-if="row.erpUserId">{{ row.erpUserId }}</span>
        <span v-else>--</span>
      </template>
    </el-table-column>
    <el-table-column prop="isDisable" label="账号状态" min-width="80" fixed="right">
      <template slot-scope="scope">
        {{ scope.row.isDisable == 0 ? '启用' : '停用' }}
      </template>
    </el-table-column>
    <el-table-column label="操作" min-width="160" fixed="right">
      <template slot-scope="scope">
        <el-button type="text" size="small" @click="edit(scope.row)">编辑</el-button>
        <el-button v-if="scope.row.isAdmin == 0" type="text" size="small" @click="reset(scope.row)"
          >密码重置</el-button
        >
        <el-button
          v-if="scope.row.isAdmin == 0"
          type="text"
          size="small"
          @click="isEnable(scope.row, scope.row.isDisable == 1 ? 0 : 1)"
          >{{ scope.row.isDisable == 1 ? '启用' : '停用' }}</el-button
        >
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { resetPwdByIds } from '@/api/user'
export default {
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectData: [],
      defaultImage: require('../../../assets/default-service.png'),
    }
  },
  computed: {
    mainPost() {
      return (list) => {
        if (!list) return
        return list
          .filter((v) => v.isProminent)
          .map((v) => v.postName)
          .join()
      }
    }, // 主岗位

    partTimePost() {
      return (list) => {
        if (!list) return
        return list
          .filter((v) => !v.isProminent)
          .map((v) => v.postName)
          .join()
      }
    }, // 兼职岗位
  },
  methods: {
    selectable(row) {
      console.log(row.isAdmin === 0)
      return row.isAdmin === 0
    },
    edit(row) {
      this.$emit('edit', { ...row })
    },
    isEnable(row, e) {
      this.$emit('isEnable', row, e)
    },
    reset(row) {
      this.$confirm('是否确定重置密码为“dp123456”', '重置密码确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          resetPwdByIds({
            data: {
              storeUserIds: [row.storeUserId],
            },
          }).then(() => {
            this.$message({
              type: 'success',
              message: '重置成功!',
            })
          })
        })
        .catch(() => {})
    },

    handleSelectionChange(val) {
      this.selectData = val
      this.$emit('handleSelectionChange', val)
    },
  },
}
</script>

<style lang="less" scoped>
.userInfo {
  display: flex;
  .img {
    width: 43px;
    height: 43px;
    margin-right: 8px;
    border-radius: 4px;
  }
  .userId {
    color: #b5b8bb;
  }
  .overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; /*行数*/
  }
}
</style>
