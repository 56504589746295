<template>
  <div class="upload">
    <el-upload
      accept=".jpg,.png"
      :disabled="disabled"
      class="my-uploader"
      :action="action"
      :show-file-list="false"
      :before-upload="beforeUpload"
      :on-error="upError"
      :on-success="handleAvatarSuccess"
    >
      <div v-loading="loading">
        <img v-if="imageUrl" :src="imageUrl" class="avatar" />
        <div v-else class="img_content">
          <i class="el-icon-plus avatar-uploader-icon"></i>
          <div class="text">{{ text }}</div>
        </div>
      </div>
    </el-upload>
    <div v-if="isTips" class="tips">
      <div>仅支持jpg/png格式，</div>
      <div>不超过1M</div>
      <div>建议尺寸240×240px</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    imageUrl: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '1',
    },
    isTips: {
      type: Boolean,
      default: false,
    },
    setDisabled: {
      type: Boolean,
      default: false,
    },
    maxSize: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      action: process.env.VUE_APP_BASE_API + 'misc-web-api/common/file/uploadImage',
      loading: false,
      disabled: false,
    }
  },
  watch: {
    setDisabled(val) {
      this.disabled = val
    },
  },
  created() {
    this.disabled = this.setDisabled
  },
  methods: {
    beforeUpload(file) {
      this.$emit('update:imageUrl', '')
      const isLt2M = file.size / 1024 / 1024 < (this.maxSize || 1)

      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 ' + (this.maxSize || 1) + 'MB!')
      } else {
        this.loading = true
        this.disabled = true
      }

      return !!isLt2M
    },
    upError() {
      this.loading = false
      this.disabled = false
    },
    handleAvatarSuccess(res) {
      this.loading = false
      this.disabled = false
      const imageUrl = res.data.http
      this.$emit('update:imageUrl', imageUrl)
    },
  },
}
</script>

<style lang="less">
.my-uploader {
  display: inline-block;
  line-height: 0;
  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    background-color: #f5f5f5;
  }
  .el-upload:hover {
    border-color: #409eff;
  }
  .img_content {
    color: #8c939d;
    width: 120px;
    height: 120px;
    display: flex;
    font-size: 20px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    line-height: 1;
    .text {
      font-size: 13px;
    }
  }
  .avatar {
    width: 120px;
    height: 120px;
    display: block;
  }
}
</style>
<style lang="scss" scoped>
.tips {
  display: inline-block;
  line-height: 1.6;
  font-size: 12px;
  color: #9a9d9e;
  margin-left: 10px;
}
.upload {
  display: flex;
}
</style>
