<template>
  <el-dropdown v-if="filterPostList && filterPostList.length" @command="onCommand">
    <span class="el-dropdown-link"> {{ checkPost.text || '全部' }}<i class="el-icon-arrow-down el-icon--right"></i> </span>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item :command="{ text: '', value: '' }">全部</el-dropdown-item>
      <el-dropdown-item v-for="(item, index) in filterPostList" :key="index" :command="item">{{ item.text }}</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  props: {
    filterPostList: {
      type: Array,
      default: () => [],
    },
    checkPost: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    onCommand(e) {
      console.log(e)
      // this.$emit('input', e)
      this.$emit('change', e)
    },
  },
}
</script>

<style></style>
