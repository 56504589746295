<template>
  <el-dialog
    :close-on-click-modal="false"
    title="编辑服务人员"
    :visible.sync="dialogVisible"
    width="994px"
    top="max(calc((100vh - 820px) / 2), 5vh)"
    :before-close="handleClose"
    destroy-on-close
  >
    <!-- <div class="warning-tips">
      <div class="warning-tips__text">
        添加服务人员前，让服务人员依次扫码注册小程序并关注服务商公众号
      </div>
      <el-popover v-model="isShowQRCode" placement="bottom" trigger="hover">
        <div class="popover">
          <div class="popover-item">
            <div class="popover-item__title">扫一扫</div>
            <div class="popover-item__title">注册小程序</div>
            <img
              class="qrcode"
              src="https://dapianlaile.oss-cn-chengdu.aliyuncs.com/APPstatic/gh_4b377686955f_430.jpg"
            />
          </div>
          <div class="popover-item">
            <div class="popover-item__title">扫一扫</div>
            <div class="popover-item__title">关注服务商公众号</div>
            <img class="qrcode" src="@/static/service_qrcode.jpg" />
          </div>
        </div>
        <div slot="reference" class="reference">
          <div class="el-icon-menu reference__icon"></div>
          <div
            class="reference__selected"
            :class="isShowQRCode ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
          ></div>
        </div>
      </el-popover>
    </div> -->

    <div style="border-bottom: 1px solid #ededed">
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="100px"
        class="demo-ruleForm"
        size="small"
      >
        <div class="left">
          <div class="form-label center">
            基本信息
            <span class="tip-text">(所有门店通用信息)</span>
          </div>
          <el-form-item label="员工姓名" prop="userName">
            <el-input
              v-model="ruleForm.userName"
              maxlength="10"
              size="small"
              style="width: 262px"
              placeholder="请输入"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="登录手机号" prop="phoneNumber">
            <el-input
              v-model="ruleForm.phoneNumber"
              disabled
              size="small"
              style="width: 262px"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="工作性质" prop="isFullTime">
            <el-radio-group v-model="ruleForm.isFullTime" size="small" style="width: 100%" disabled>
              <el-radio :label="1" border style="margin-right: 0"> 全职 </el-radio>
              <el-radio :label="0" border>兼职</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="ERP员工ID">
            <!-- <el-input size="small" style="width: 200px" v-model="ruleForm.erpUserId" placeholder="请输入"></el-input> -->
            <el-autocomplete
              v-model="erpUser"
              value-key="empno"
              label="empname"
              :fetch-suggestions="querySearchStaffAsync"
              placeholder="员工姓名搜索"
              style="width: 262px"
              popper-class="my-autocomplete"
              disabled
            >
              <template slot-scope="{ item }">
                <div @click="onErpUserChange(item)">
                  <div class="prdtname">
                    <span>{{ item.empname + ' - ' }}</span>
                    <span>{{ item.empno }}</span>
                  </div>
                  <div>
                    <!-- <span class="brdtype">{{ item.brandno + ' - ' }}</span> -->
                    <span class="brdtype">{{ item.postname }}</span>
                  </div>
                </div>
              </template>
            </el-autocomplete>
          </el-form-item>
          <el-form-item label="员工照片" prop="avatarUrl">
            <UploadImg
              :is-tips="true"
              :set-disabled="true"
              :image-url.sync="ruleForm.avatarUrl"
              text="上传图片"
            />
          </el-form-item>
          <!-- <el-form-item label="账号状态" prop="isDisable">
          <el-radio-group :disabled="ruleForm.isAdmin == 1" v-model="ruleForm.isDisable" style="width: 240px">
            <el-radio :label="0">启用</el-radio>
              <el-radio :label="1">禁用</el-radio>
            </el-radio-group>
          </el-form-item> -->

          <!-- <el-form-item label="角色权限" prop="roleId">
            <el-select
              v-model="ruleForm.roleId"
              :disabled="ruleForm.isAdmin == 1"
              style="width: 262px"
              size="small"
              placeholder="请选择角色权限"
            >
              <el-option
                v-for="(n, i) in roleList"
                v-show="n.adminType != 2"
                :key="i"
                :label="n.roleName"
                :value="n.roleId"
              ></el-option>
            </el-select>
          </el-form-item> -->
          <!-- <el-form-item label="在职状态：" prop="isResign">
            <el-radio-group v-model="ruleForm.isResign" style="width: 240px">
              <el-radio :label="0">在职</el-radio>
              <el-radio :label="1">离职</el-radio>
            </el-radio-group>
          </el-form-item> -->
          <el-form-item label="等级" prop="userLevel">
            <el-select
              v-model="ruleForm.userLevel"
              style="width: 262px"
              size="small"
              placeholder="请选择等级"
              disabled
              @change="levelChange"
            >
              <el-option
                v-for="level in levelList"
                :key="level.dictionaryKey"
                :label="level.dictionaryName"
                :value="parseInt(level.dictionaryValue)"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="可服务日" prop="serviceDayValueList">
            <el-checkbox-group v-model="ruleForm.serviceDayValueList" disabled style="width: 100%">
              <el-checkbox v-for="(n, i) in workDays" :key="i" border :label="n.dictionaryValue">{{
                n.dictionaryName
              }}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <div class="form-label">门店信息</div>
          <el-form-item label="与本门店关系" prop="isResident" label-width="120px">
            <el-radio-group v-model="ruleForm.isResident" size="small" style="width: 100%">
              <el-radio :label="1" border style="margin-right: 0">常驻</el-radio>
              <el-radio :label="2" border>关联</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <span class="divider"></span>
        <div class="right">
          <div class="form-label center" style="margin-left: 0">
            岗位信息
            <span class="tip-text">(所有门店通用信息)</span>
          </div>
          <el-checkbox-group v-model="ruleForm.postIdList" style="width: 100%">
            <el-checkbox
              v-for="(post, i) in postList"
              :key="i"
              disabled
              border
              :label="post.postId"
              class="postNameClass"
              @change="postChange($event, post)"
            >
              <span>
                {{ post.postName }}
              </span>
              <img v-show="post.isProminent" src="@/assets/mainPostImg.png" alt="" srcset="" />
            </el-checkbox>
          </el-checkbox-group>

          <el-tabs
            v-if="ruleForm.userPostInfoSaveList && ruleForm.userPostInfoSaveList.length"
            v-model="activeName"
            type="border-card"
            style="margin-bottom: 20px"
          >
            <el-tab-pane
              v-for="item in ruleForm.userPostInfoSaveList"
              :key="item.postName"
              :label="item.postName"
              :name="item.postId"
            >
              <el-form-item label="岗位性质" required style="height: 33px">
                <el-checkbox
                  v-model="item.isProminent"
                  disabled
                  @change="mainPostChange($event, item)"
                >
                  设为主岗位
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="主岗位仅可设置一个，若未设为主岗位，则为兼职岗位"
                    placement="top"
                  >
                    <i class="el-icon-warning-outline tipIcon"></i>
                  </el-tooltip>
                </el-checkbox>
              </el-form-item>
              <template v-if="item.postId == 298">
                <el-form-item label="从业年限：" required>
                  <el-input-number
                    v-model="item.years"
                    disabled
                    :min="1"
                    label="仅支持≥1的整数"
                  ></el-input-number>
                  <span style="margin-left: 6px">年</span>
                </el-form-item>
                <el-form-item label="职级" required>
                  <el-select
                    v-model="item.rank"
                    disabled
                    style="width: 100%"
                    size="small"
                    placeholder="请选择职级"
                  >
                    <el-option
                      v-for="rank in rankList"
                      :key="rank.dictionaryKey"
                      :label="rank.dictionaryName"
                      :value="rank.dictionaryValue"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="荣誉称号" required>
                  <el-select
                    v-model="item.honor"
                    disabled
                    style="width: 100%"
                    size="small"
                    multiple
                    placeholder="荣誉称号"
                  >
                    <el-option
                      v-for="honor in honorList"
                      :key="honor.dictionaryKey"
                      :label="honor.dictionaryName"
                      :value="honor.dictionaryValue"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="描述简介" required>
                  <el-input
                    v-model="item.description"
                    disabled
                    type="textarea"
                    :autosize="{ minRows: 4, maxRows: 5 }"
                    placeholder="请输入描述简介"
                    maxlength="60"
                    show-word-limit
                    style="width: 100%"
                  ></el-input>
                </el-form-item>
              </template>
              <!-- <template v-else>
                <el-empty description="无需填写" :image-size="96"></el-empty>
              </template> -->
            </el-tab-pane>
          </el-tabs>
          <div
            class="form-label"
            style="margin-left: 0"
            :style="{ 'margin-top': ruleForm.userPostInfoSaveList.lenght ? '' : '20px' }"
          >
            权限信息
            <el-tooltip
              class="item"
              effect="dark"
              content="实际权限为以下权限的并集"
              placement="top"
            >
              <i class="el-icon-warning-outline infoIcon"></i>
            </el-tooltip>
          </div>
          <!-- <div class="author-info">
            <span class="author-info-title">基础权限</span>
            <div class="author-info-items">
              <span v-for="item in 10" :key="item" class="item">摄影师</span>
            </div>
          </div> -->
          <div class="author-info">
            <span class="author-info-title">设置权限</span>
            <el-select
              v-model="ruleForm.roleId"
              :disabled="ruleForm.isAdmin == 1"
              style="width: 262px"
              size="small"
              placeholder="请选择权限"
            >
              <template v-for="(n, i) in roleList">
                <el-option
                  v-if="n.adminType === 0 || (n.adminType !== 0 && ruleForm.isAdmin === 1)"
                  :key="i"
                  :label="n.roleName"
                  :value="n.roleId"
                ></el-option>
              </template>
            </el-select>
          </div>
        </div>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="handleClose">取 消</el-button>
      <el-button :loading="btnLoading" size="small" type="primary" @click="ok">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import UploadImg from '@/components/uploadImg'
import {
  checkExistUser,
  userAdd,
  getRoleList,
  updateResidentByIds,
  selectByToken,
  getDictionaryListByKey,
} from '@/api/user'
import { searchemp } from '@/api/shed-space'
export default {
  components: { UploadImg },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    postList: {
      type: Array,
      default: () => [],
    },
    row: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: '',
    },
    workDays: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    const validatePhone = (rule, value, callback) => {
      if (!/^1[3-9]\d{9}$/.test(value)) {
        callback(new Error('请输入正确的手机号'))
      } else {
        callback()
      }
    }
    return {
      ruleForm: {
        postIdList: [],
        serviceDayValueList: [],
        userLevel: '',
        userLevelName: '',
        isResign: 0,
        isDisable: 0,
        userPostInfoSaveList: [],
        erpUserId: '',
        isResident: '',
        isFullTime: '',
        roleId: '',
      },
      tableData: [],
      rules: {
        isFullTime: [{ required: true, message: '工作性质', trigger: 'change' }],
        userName: [{ required: true, message: '请输入员工姓名', trigger: 'blur' }],
        phoneNumber: [
          { required: true, message: '请输入登录手机号', trigger: 'blur' },
          { validator: validatePhone, trigger: 'blur' },
        ],
        isDisable: [{ required: true, message: '请选择账号状态', trigger: 'change' }],
        roleId: [{ required: true, message: '请选择角色权限', trigger: 'change' }],
        isResign: [{ required: true, message: '请选择在职状态', trigger: 'change' }],
        postIdList: [{ required: true, message: '请选择岗位', trigger: 'change' }],
        avatarUrl: [{ required: true, message: '请上传照片', trigger: 'change' }],
        erpUserId: [{ required: true, message: '请录入ERP员工ID', trigger: 'change' }],
        isResident: [{ required: true, message: '请选择与本门店关系', trigger: 'change' }],
        serviceDayValueList: [{ required: true, message: '请选择可服务日', trigger: 'change' }],
      },
      checkedPosts: [],
      roleList: [],
      btnLoading: false,
      dptno: '',
      levelList: [],
      isShowQRCode: false,
      inputVisible: false,
      inputValue: '',
      storeId: '',
      rankList: [],
      honorList: [],
      activeName: '',
      erpUser: '',
    }
  },
  watch: {
    dialogVisible: function (val) {
      this.$nextTick(() => {
        // this.$refs.ruleForm.resetFields()
        this.ruleForm = {
          ...this.ruleForm,
          postIdList: [],
          serviceDayValueList: ['2', '3', '4', '5', '6', '7'],
          userPostInfoSaveList: [],
          isResign: 0,
          isDisable: 0,
        }
        this.erpUser = ''
        if (val && this.type === 'edit') {
          const { storeUserPostInfoList: userPostInfoSaveList, ...row } = this.row
          this.ruleForm = {
            ...row,
            postIdList: this.getPsotIds(this.row),
            roleId: (this.row.roleList || []).length > 0 ? this.row.roleList[0].roleId : '',
            userPostInfoSaveList: (userPostInfoSaveList || []).map((v) => ({
              ...v,
              isProminent: Boolean(v.isProminent),
              rank: v.rank?.dictionaryValue,
              honor: v.honor?.map((v) => v.dictionaryValue),
            })),
          }
          this.erpUser = row.erpUserId || ''
          this.hanldeOldPostData()
          this.defaultActiveTab()
        }
      })
    },
  },
  mounted() {
    this.getSelectByToken()
    getRoleList().then((res) => {
      this.roleList = res.data
    })
    this.getProductLevelList()
    this.getPhotographerRank()
    this.getPhotographerHonor()
  },
  methods: {
    getPsotIds(data) {
      let ids = []
      if (data.mainPost) ids = [...ids, data.mainPost.postId]
      if (data.partTimePostList) ids = [...ids, ...data.partTimePostList.map((e) => e.postId)]
      return ids
    },
    // 所有岗位只能有一个主岗位
    mainPostChange(e, item) {
      this.ruleForm.userPostInfoSaveList.forEach((v, i) => {
        if (v.postId !== item.postId) {
          this.$set(this.ruleForm.userPostInfoSaveList, i, {
            ...v,
            isProminent: false,
          })
        }
      })
      this.postList.forEach((v, i) => {
        if (v.postId !== item.postId) {
          this.postList[i].isProminent = false
        } else {
          this.postList[i].isProminent = e
        }
      })
      console.log(e, '====', this.postList)
    },
    onErpUserChange(e) {
      console.log(e)
      this.ruleForm.erpUserId = e.empno
    },
    /**
     * 处理老数据
     */
    hanldeOldPostData() {
      if (!this.ruleForm.userPostInfoSaveList) {
        this.ruleForm.userPostInfoSaveList =
          this.row.postList.map((e) => {
            return {
              storeId: this.storeId,
              postId: e.postId,
              postName: e.postName,
              years: 1,
              rank: '',
              honor: [],
              description: '',
            }
          }) || []
      }
      const obj = this.ruleForm.userPostInfoSaveList.find((v) => v.isProminent)
      this.postList = this.postList.map((v) => {
        if (obj && v.postId === obj.postId) {
          return { ...v, isProminent: true }
        } else {
          return {
            ...v,
            isProminent: false,
          }
        }
      })
    },
    postChange(e, item) {
      if (e) {
        this.ruleForm.userPostInfoSaveList.push({
          storeId: this.storeId,
          postId: item.postId,
          postName: item.postName,
          years: 1,
          rank: '',
          honor: [],
          description: '',
        })
      } else {
        item.isProminent = false
        this.ruleForm.userPostInfoSaveList = this.ruleForm.userPostInfoSaveList.filter(
          (el) => el.postId !== item.postId,
        )
      }
      if (e || item.postId === this.activeName) this.defaultActiveTab()
    },
    removeTab(e) {
      this.postList.find((el) => el.postId === e).isProminent = false

      this.ruleForm.userPostInfoSaveList = this.ruleForm.userPostInfoSaveList.filter(
        (el) => el.postId !== e,
      )
      this.ruleForm.postIdList = this.ruleForm.postIdList.filter((el) => el !== e)

      if (e === this.activeName) this.defaultActiveTab()
    },
    defaultActiveTab() {
      const list = Array.from(this.ruleForm.userPostInfoSaveList)
      if (list.length > 0) {
        this.activeName = list.at(-1).postId
      }
    },
    levelChange(e) {
      console.log(e)
      const level = this.levelList.find((el) => parseInt(el.dictionaryValue) === e)
      console.log(level)
      this.ruleForm.userLevelName = level.dictionaryName
    },
    querySearchStaffAsync(queryString, cb) {
      console.log(queryString)
      this.ruleForm.erpUserId = ''
      searchemp({
        data: {
          keyname: queryString,
        },
      }).then((res) => {
        console.log(res.data)
        cb(res.data)
      })
    },
    getSelectByToken() {
      selectByToken().then((res) => {
        console.log(res)
        this.storeId = res.data.storeId
        this.dptno = res.data.erpDeptId
      })
    },
    async getProductLevelList() {
      try {
        const res = await getDictionaryListByKey({ data: { key: 'product_level' } })
        console.log(res)
        this.levelList = res.data.childs
      } catch (e) {
        console.log(e)
      }
    },
    /**
     * 职级
     */
    async getPhotographerRank() {
      try {
        const res = await getDictionaryListByKey({ data: { key: 'photographer_rank' } })
        console.log(res)
        this.rankList = res.data.childs
      } catch (e) {
        console.log(e)
      }
    },
    /**
     * 称号
     */
    async getPhotographerHonor() {
      try {
        const res = await getDictionaryListByKey({ data: { key: 'photographer_honor' } })
        console.log(res)
        this.honorList = res.data.childs
      } catch (e) {
        console.log(e)
      }
    },
    handleClose() {
      this.$emit('update:dialogVisible', false)
    },
    userAdd(data) {
      userAdd({ data })
        .then(() => {
          this.btnLoading = false
          this.$emit('update:dialogVisible', false)

          this.$emit('refresh')
          this.$message({
            message: '操作成功',
            type: 'success',
          })
          this.$refs.ruleForm.resetFields()
        })
        .catch(() => {
          this.btnLoading = false
        })
    },
    updateResidentByIds(data) {
      updateResidentByIds({ data })
        .then(() => {
          this.btnLoading = false
          this.$emit('update:dialogVisible', false)
          this.$emit('refresh')
          this.ruleForm.isResident = ''
          this.ruleForm.isResidenroleId = ''
          this.$message({
            message: '操作成功',
            type: 'success',
          })
          // this.$refs.ruleForm.resetFields()
        })
        .catch(() => {
          this.btnLoading = false
        })
    },
    accountExists(params) {
      this.$confirm('继续添加将覆盖更新原有员工账号', '该账号已存在', {
        confirmButtonText: '添加',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          if (!this.erpUser) {
            this.notFilled(params)
          } else {
            this.userAdd(params)
          }
        })
        .catch(() => {
          this.btnLoading = false
        })
    },
    notFilled(params) {
      this.$confirm('如需填写ERP员工ID，请返回填写', 'ERP员工ID未填写', {
        confirmButtonText: '直接提交',
        cancelButtonText: '取消',
        confirmButtonType: 'text',
        type: 'warning',
      })
        .then(() => this.userAdd(params))
        .catch(() => {
          this.btnLoading = false
        })
    },
    // 验证手机号是否已经绑定其它员工信息
    checkExistUser(params) {
      checkExistUser({ data: this.ruleForm.phoneNumber }).then(({ success, data }) => {
        if (success && data) {
          this.accountExists(params)
        }
        if (success && !data) {
          if (!this.erpUser) {
            this.notFilled(params)
          } else {
            this.userAdd(params)
          }
        }
      })
    },
    // 处理岗位数据
    handlePostData(data) {
      return data.userPostInfoSaveList.map((v) => {
        if (v.postId === '298') {
          return {
            ...v,
            rank: {
              dictionaryValue: v.rank,
              dictionaryName: this.rankList.find((r) => r.dictionaryValue === v.rank)
                .dictionaryName,
            },
            honor: v.honor.map((h) => ({
              dictionaryValue: h,
              dictionaryName: this.honorList.find((f) => f.dictionaryValue === h).dictionaryName,
            })),
          }
        } else {
          return { ...v, rank: null, honor: null }
        }
      })
    },
    ok() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const { storeUserId, isResident, roleId } = this.ruleForm
          const data = {
            storeUserIds: [storeUserId],
            isResident,
            roleIdList: roleId ? [roleId] : [],
          }
          this.btnLoading = true
          if (this.type === 'add') {
            this.checkExistUser(data)
          }
          if (this.type === 'edit') {
            this.updateResidentByIds(data)
          }
        }
      })
    },
    onSubmit() {},
    reset() {},
    showQRCode() {
      this.isShowQRCode = !this.isShowQRCode
    },
  },
}
</script>

<style lang="scss" scoped>
.center {
  align-items: center;
}
.tip-text {
  font-size: 12px;
  color: #999999;
  height: 18px;
}
::v-deep .el-tabs__header .el-tabs__item {
  color: #333333;
}
.postNameClass {
  position: relative;
  img {
    width: 16px;
    height: 16px;
    position: absolute;
    right: -2px;
    top: -1px;
  }
}
.author-info {
  display: flex;
  align-items: flex-start;
  &-title {
    padding: 6px 10px;
  }
  &-items {
    flex: 1;
    // margin-left: 6px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    .item {
      background: #ededed;
      border-radius: 4px 4px 4px 4px;
      color: #9a9d9e;
      padding: 6px 10px;
    }
  }
  &:last-child {
    margin-top: 20px;
  }
}
.tipIcon {
  transform: rotate(180deg);
  font-size: 16px;
  color: #909399;
  margin-left: 3px;
}
.infoIcon {
  transform: rotate(180deg) translateY(2px);
  font-size: 16px;
  color: #909399;
  margin-left: 3px;
}
.el-form-item {
  margin-right: 10px !important;
}
::v-deep .el-dialog__body {
  padding-top: 0 !important;
}
.demo-ruleForm {
  display: flex;
  flex-wrap: wrap;
}

.warning-tips {
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 100%;
  height: 48px;
  background-color: #edf2fc;
  border: 1px solid #e7edfb;
  box-sizing: border-box;

  &__text {
    color: #363f47;
    font-size: 16px;
    font-weight: 400;
    margin-right: 8px;
  }

  .tips-icon {
    color: #999;
    font-size: 16px;
    margin-left: 10px;
    cursor: pointer;
  }
}
.reference {
  display: inline-flex;
  align-items: center;
  &__icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #363f47;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 10px;
  }
  &__selected {
    color: #363f47;
    font-size: 10px;
    margin-left: 4px;
  }
}
.popover {
  display: flex;
  align-items: center;
  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &:not(:last-child) {
      margin-right: 48px;
    }
    &__title {
      color: #333333;
      font-size: 14px;
      font-weight: 400;
    }
  }
}
.qrcode {
  width: 144px;
  height: 144px;
  object-fit: cover;
  margin-top: 8px;
}
::v-deep .el-dialog__body {
  padding: 0;
}
::v-deep .el-popper {
  padding: 12px !important;
}
.left {
  width: 432px;
  display: inline-block;
  padding: 32px 20px;
  box-sizing: border-box;
  ::v-deep .el-checkbox {
    width: 74px;
    margin-right: 10px;
    margin-bottom: 12px;
    &:nth-child(3n + 1) {
      margin-left: 0;
    }
  }
}
.right {
  width: calc(100% - 433px);
  display: inline-block;
  padding: 32px 20px;
  padding-left: 42px;
  box-sizing: border-box;
  ::v-deep .el-checkbox {
    width: 90px;
    margin-right: 12px;
    margin-bottom: 12px;
    margin-left: 0 !important;
    &:nth-child(5n) {
      margin-right: 0;
    }
  }
  ::v-deep .el-tabs--border-card {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 4px 12px 1px rgba(0, 0, 0, 0.05);

    border: 1px solid #ededed;
  }
}
.divider {
  width: 1px;
  background-color: #ededed;
}
.form-label {
  color: #111111;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  display: flex;
  margin-left: 8px;
  margin-bottom: 26px;
  &::before {
    content: '';
    width: 3px;
    height: 22px;
    border-radius: 0 3px 3px 0;
    background-color: #363f47;
    margin-right: 8px;
    display: inline-block;
  }
}
::v-deep .el-tabs__nav-prev {
  line-height: 39px;
}
::v-deep .el-tabs__nav-next {
  line-height: 39px;
}
</style>
<style lang="scss">
.my-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .prdtname {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .brdtype {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .brdtype {
      color: #ddd;
    }
  }
}

.demo-ruleForm {
  .el-tag {
    margin-right: 10px;
  }
  .button-new-tag {
    height: 28px;
    line-height: 26px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    vertical-align: bottom;
  }
}
</style>
